import Vue from 'vue';
import VueRouter from 'vue-router';
import { ROUTER_PATHS } from './paths';

Vue.use(VueRouter);

export const routes = [{
    path: ROUTER_PATHS.ACCOUNT_ACTIVATED_SUCCESS,
    name: 'Download-Success',
    component: () => import('@/views/DownloadSuccessView/DownloadSuccessView.vue'),
    meta: {
        title: 'Download Success',
    },
},
{
    path: ROUTER_PATHS.DOWNLOAD_FLAGSHIP,
    name: 'Flagship-Download-Apple',
    component: () => import('@/views/FlagshipDownloadAppleView/FlagshipDownloadAppleView.vue'),
    meta: {
        title: 'Download Apple',
    },
},
{
    path: ROUTER_PATHS.CODE_VALIDATION,
    name: 'Email-Code-View',
    component: () => import('@/views/EmailCodeView/EmailCodeView.vue'),
    props: true,
    meta: {
        title: 'Validate email code',
    },
},
{
    path: ROUTER_PATHS.LOGIN_SUCCESSFUL,
    name: 'Login-Successful-Activated',
    component: () => import('@/views/LoginSuccessView/LoginSuccessView.vue'),
    meta: {
        device: 'windows',
        title: 'Activation success',
    },
},
{
    // Platform redirects here when coming from successful xsolla login
    path: ROUTER_PATHS.ACCOUNT_WINDOWS_LOGIN_SUCCESSFUL,
    name: 'Cross-Roads',
    component: () => import('@/views/CrossRoadsView/CrossRoadsView.vue'),
    meta: {
        device: 'windows',
        title: 'Activation success',
    },
},
{
    path: ROUTER_PATHS.ACCOUNT_ACTIVATED_ERROR,
    name: 'Activation-Error',
    component: () => import('@/views/ActivationExpiredView/ActivationExpiredView.vue'),
    meta: {
        title: 'Activation error',
    },
},
{
    path: ROUTER_PATHS.HOME,
    name: 'Account-Home-View',
    component: () => import('@/views/AccountHomeView/AccountHomeView.vue'),
    meta: {
        title: 'Voicemod Account',
    },
},
{
    path: ROUTER_PATHS.AUTHORIZE,
    name: 'Authorize-Consents-View',
    component: () => import('@/views/AuthorizeConsentsView/AuthorizeConsentsView.vue'),
    meta: {
        title: 'Voicemod Authorize Consents',
    },
},
{
    path: ROUTER_PATHS.CHECKOUT,
    name: 'Checkout',
    component: () => import('@/views/CheckoutView/CheckoutView.vue'),
    meta: {
        title: 'Voicemod Checkout',
    },
},
{
    path: ROUTER_PATHS.FLAGSHIP_CHECKOUT,
    name: 'Flagship-Checkout',
    component: () => import('@/views/FlagshipCheckoutView/FlagshipCheckoutView.vue'),
    meta: {
        title: 'Voicemod Checkout',
    },
},
{
    path: ROUTER_PATHS.PAYMENT_CHECK,
    name: 'Payment-Check',
    component: () => import('@/views/PaymentCheckView/PaymentCheckView.vue'),
    meta: {
        title: 'Voicemod Payment check',
    },
},
{
    path: ROUTER_PATHS.PAYMENT_SUCCESS,
    name: 'Payment-Success',
    component: () => import('@/views/PaymentSuccessView/PaymentSuccessView.vue'),
    meta: {
        title: 'Voicemod payment success',
    },
},
{
    path: ROUTER_PATHS.PAYMENT_ERROR,
    name: 'Payment-Error',
    component: () => import('@/views/PaymentErrorView/PaymentErrorView.vue'),
    meta: {
        title: 'Voicemod payment error',
    },
},
{
    path: ROUTER_PATHS.USER_ACCOUNT,
    name: 'User-Account',
    component: () => import('@/views/UserAccountView/UserAccountView.vue'),
    meta: {
        title: 'Voicemod User Account',
    },
},
{
    path: ROUTER_PATHS.CROSSROADS,
    name: 'Cross-Roads',
    component: () => import('@/views/CrossRoadsView/CrossRoadsView.vue'),
    meta: {
        title: 'Redirecting',
    },
},
{
    path: ROUTER_PATHS.VOICEMOD_CONTROLLER_APP,
    name: 'Voicemod-Controller-App',
    component: () => import('@/views/ControllerAppView/ControllerAppView.vue'),
    meta: {
        title: 'Voicemod Controller App',
    },
},
{
    path: ROUTER_PATHS.VOICEMOD_SOUNDBOARD_APP,
    name: 'Voicemod-Soundboard-App',
    component: () => import('@/views/SoundboardAppView/SoundboardAppView.vue'),
    meta: {
        title: 'Voicemod Soundboard App',
    },
},
{
    path: ROUTER_PATHS.FLAGSHIP_LOGIN_SUCCESS,
    name: 'Flagship-login-success',
    component: () => import('@/views/FlagshipLoginSuccessView/FlagshipLoginSuccessView.vue'),
    meta: {
        title: 'Activation success',
    },
},
{
    path: ROUTER_PATHS.AI_BETA_PROGRAM_ACCEPTED,
    name: 'AI-Beta-Program-Accepted',
    component: () => import('@/views/BetaProgramAcceptedView/BetaProgramAcceptedView.vue'),
    meta: {
        title: 'AI Beta Program Accepted',
    },
},
{
    path: ROUTER_PATHS.STORE,
    name: 'Store',
    component: () => import('@/views/StoreView/StoreView.vue'),
    meta: {
        title: 'Store',
    },
},
{
    path: ROUTER_PATHS.TTSING,
    name: 'TTSing-login-success',
    component: () => import('@/views/TTSingLoginSuccessView/TTSingLoginSuccessView.vue'),
    meta: {
        title: 'TTSing login success',
    },
},
{
    path: ROUTER_PATHS.TUNA,
    name: 'Tuna-login-success',
    component: () => import('@/views/TunaLoginSuccessView/TunaLoginSuccessView.vue'),
    meta: {
        title: 'Tuna login success',
    },
},
{
    path: ROUTER_PATHS.VMGO,
    name: 'VM-go-login-success',
    component: () => import('@/views/VMGoLoginSuccessView/VMGoLoginSuccessView.vue'),
    meta: {
        title: 'VM Go login success',
    },
},
{
    path: ROUTER_PATHS.VM_SOUNDBOARD,
    name: 'VM-soundboard-login-success',
    component: () => import('@/views/VMSoundboardLoginSuccessView/VMSoundboardLoginSuccessView.vue'),
    meta: {
        title: 'VM Soundboard login success',
    },
},
{
    path: ROUTER_PATHS.COUPON,
    name: 'Coupon-login-success',
    component: () => import('@/views/CouponLoginSuccessView/CouponLoginSuccessView.vue'),
    meta: {
        title: 'Redeem a coupon login success',
    },
},
{
    path: ROUTER_PATHS.BETA_PROGRAM_ACCEPTED,
    name: 'Beta-Program-Accepted',
    component: () => import('@/views/NewBetaProgramAcceptedView/NewBetaProgramAcceptedView.vue'),
    meta: {
        title: 'Beta Program Accepted',
    },
},
{
    path: '*',
    name: 'Error-404',
    component: () => import('@/views/Error404View/Error404View.vue'),
    meta: {
        title: 'Page not found',
    },
},
{
    path: ROUTER_PATHS.BEG,
    name: 'Beg-a-license',
    component: () => import('@/views/BegView/BegView.vue'),
    meta: {
        title: 'Beg Voicemod',
    },
},
{
    path: ROUTER_PATHS.BEG_GIFTING,
    name: 'Beg-gifting',
    component: () => import('@/views/BegGiftingView/BegGiftingView.vue'),
    meta: {
        title: 'Beg Voicemod',
    },
},
{
    path: ROUTER_PATHS.GIFT,
    name: 'Gift-a-license',
    component: () => import('@/views/GiftView/GiftView.vue'),
    meta: {
        title: 'Gift Voicemod',
    },
},
{
    path: ROUTER_PATHS.GIFT_SUCCESS,
    name: 'Gift-a-license-success',
    component: () => import('@/views/GiftSuccessView/GiftSuccessView.vue'),
    meta: {
        title: 'Gift Voicemod Succeed',
    },
},
{
    path: ROUTER_PATHS.VOICEROOM,
    name: 'Voice-room-success-view',
    component: () => import('@/views/VoiceRoomSuccessView/VoiceRoomSuccessView.vue'),
    meta: {
        title: 'Voice Room Succeed',
    },
},
{
    path: ROUTER_PATHS.PADDLE_PAYMENT_CHECK,
    name: 'Paddle-payment-check',
    component: () => import('@/views/PaddlePaymentCheckView/PaddlePaymentCheckView.vue'),
    meta: {
        title: 'Paddle Payment Check',
    },
},{
    path: ROUTER_PATHS.DELETED_ACCOUNT,
    name: 'Deleted-account',
    component: () => import('@/views/DeletedAccountView/DeletedAccountView.vue'),
    meta: {
        title: 'Account Deleted',
    },
},{
    path: ROUTER_PATHS.REDIRECT_MOBILE_DOWNLOAD,
    name: 'Redirect-mobile-download',
    component: () => import('@/views/RedirectMobileDownload/RedirectMobileDownload.vue'),
    meta: {
        title: 'Redirect Mobile Download',
    },
}];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
});

export default router;