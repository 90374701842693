const ROUTER_PATHS = {
    ACCOUNT_ACTIVATED_SUCCESS: '/account-activated-success',
    ACCOUNT_WINDOWS_LOGIN_SUCCESSFUL: '/account-windows-login-successful',
    ACCOUNT_ACTIVATED_ERROR: '/account-activated-error',
    AUTHORIZE: '/authorize',
    CHECKOUT: '/checkout',
    FLAGSHIP_CHECKOUT: '/flagship-checkout',
    CODE_VALIDATION: '/code-validation',
    CROSSROADS: '/cross-roads',
    DOWNLOAD_FLAGSHIP: '/download-apple',
    ERROR_404: '/error-404',
    HOME: '/',
    LOGIN_SUCCESSFUL: '/login-successful',
    PAYMENT_CHECK: '/payment-check',
    PAYMENT_SUCCESS: '/payment-success',
    PAYMENT_ERROR: '/payment-error',
    USER_ACCOUNT: '/user-account',
    VOICEMOD_CONTROLLER_APP: '/voicemod-controller-app',
    VOICEMOD_SOUNDBOARD_APP: '/voicemod-soundboard-app',
    UNLOCK_PROMOTION: '/unlock-promotion',
    FLAGSHIP_LOGIN_SUCCESS: '/nextgen-login-successful',
    AI_BETA_PROGRAM_ACCEPTED: '/ai-beta-program-accepted',
    STORE: '/store-redirect',
    TTSING: '/ttsing-login-success',
    TUNA: '/tuna-login-success',
    VMGO: '/vm-go-login-success',
    VM_SOUNDBOARD: '/vm-soundboard-login-success',
    COUPON: '/coupon-login-success',
    BEG: '/beg',
    BEG_GIFTING: '/begalicense',
    GIFT: '/gifting',
    GIFT_SUCCESS: '/gifting/success',
    BETA_PROGRAM_ACCEPTED: '/beta-program-accepted',
    EXTERNAL_HALLOWEEN: 'https://halloween.voicemod.net/share',
    VOICEROOM: '/voice-room-login-success',
    PADDLE_PAYMENT_CHECK: '/paddle-payment-check',
    DELETED_ACCOUNT: '/goodbye',
    REDIRECT_MOBILE_DOWNLOAD: '/redirect-mobile-download',
};

export { ROUTER_PATHS };