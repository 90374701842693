import { serializer } from './serializer';
import { userDomainModule } from './user';
import {
    SOCIAL_LOGIN_NAME,
    SOCIAL_LOGIN_STATUS,
    GIFT_PRODUCT_KEYS,
    SUBSCRIPTION_TYPES,
    BETA_CONSENT_KEY,
    BETA_CONSENT_ACCEPTED,
    BETA_CONSENT_DECLINED,
    GIFT_KEYS,
} from './constants';

const userDomain = {
    ...userDomainModule,
    serializer,
};

export {
    userDomain,
    SOCIAL_LOGIN_NAME,
    SOCIAL_LOGIN_STATUS,
    GIFT_PRODUCT_KEYS,
    SUBSCRIPTION_TYPES,
    BETA_CONSENT_KEY,
    BETA_CONSENT_ACCEPTED,
    BETA_CONSENT_DECLINED,
    GIFT_KEYS,
};