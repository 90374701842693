import { cloneDeep } from 'lodash';

import { actions } from './actions';
import { mutations } from './mutations';

const defaultState = {
    websocket: {
        ports: [59129, 39273, 42152, 43782, 46667, 35679, 37170, 38501, 33952, 30546],
        hasError: false,
        actionType: '',
        actionID: '',
        user: {
            code: null,
            userID: null,
            isNew: false,
        },
        fail: false,
    },
};

export const websocket_store = {
    namespaced: true,
    state: cloneDeep(defaultState),
    actions,
    mutations,
};