export const SOCIAL_LOGIN_NAME = {
    DISCORD: 'discord',
    TWITCH: 'twitch',
    GOOGLE: 'google',
    APPLE: 'apple',
};

export const SOCIAL_LOGIN_STATUS = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
};

export const GIFT_KEYS = {
    threeMonths: '3month',
    oneYear: '1year',
    lifetime: 'lifetime',
};

export const GIFT_PRODUCT_KEYS = {
    [GIFT_KEYS.threeMonths]: 'giftVoicemod.planOptionQuarterly',
    [GIFT_KEYS.oneYear]: 'giftVoicemod.planOptionAnnual',
    [GIFT_KEYS.lifetime]: 'giftVoicemod.planOptionLifetime',
};

export const SUBSCRIPTION_TYPES = {
    free: 'free',
    pro: 'pro',
};

export const UNSUBSCRIBE_MODAL_KEYS = {
    success: 'success',
    confirm: 'confirm',
    error: 'error',
};

export const CANCEL_MODAL_KEYS = {
    confirm: 'confirm',
};

export const OS_NAMES = {
    windows: 'Windows',
    mac: 'macOS',
};

export const BETA_CONSENT_KEY = 'beta_program';

export const BETA_CONSENT_ACCEPTED = 'accepted';

export const BETA_CONSENT_DECLINED = 'declined';