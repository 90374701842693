export const actions = {
    axn_websocketError({commit}, payload) {
        commit('set_websocketError', payload);
    },

    axn_websocketUser({commit}, payload) {
        commit('set_websocketUser', payload);
    },

    axn_websocketActionType({commit}, payload) {
        commit('set_websocketActionType', payload);
    },

    axn_websocketActionID({commit}, payload) {
        commit('set_websocketActionID', payload);
    },

    axn_websocketFail({commit}, payload) {
        commit('set_websocketFail', payload);
    },

    axn_setWebsocketPorts({commit}, payload) {
        commit('set_websocketPorts', payload);
    },
};