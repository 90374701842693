import Vue from 'vue';
import { defaultState } from './index';

export const mutations = {
    setUserInfo(state, userInfo) {
        Vue.set(state, 'userInfo', userInfo);
    },
    setEmptyUserInfo(state) {
        Vue.set(state, 'userInfo', defaultState.userInfo);
    },
    setShowProfile(state, value) {
        Vue.set(state, 'showProfile', value);
    },
};