const ONE_MONTH_IN_SECONDS = 30 * 24 * 60 * 60;

const accountUrl = () => {
    const { VUE_APP_IS_LOCAL, VUE_APP_ENVIRONMENT } = process.env;

    if (VUE_APP_IS_LOCAL) {
        return 'local.voicemod';
    }

    if (VUE_APP_ENVIRONMENT === 'production') {
        return 'voicemod.net';
    }

    return 'voicemod.dev';
};

const setCookie = (name, value, expires = null) => {
    const dateInSeconds = Math.round(Date.now() / 1000);
    const maxAge = expires ? expires - dateInSeconds : ONE_MONTH_IN_SECONDS;

    document.cookie = `vm_${name}=${value}; max-age=${maxAge};  domain=${accountUrl()}`;
};

const getCookie = (cookieName) => {
    const voicemodCookie = `vm_${cookieName}`;

    const cookies = document.cookie.split(';');

    const cookie = cookies.find((cookie) => cookie.includes(voicemodCookie));

    if (cookie) {
        return cookie.split('=')[1];
    }

    return null;
};

const deleteCookie = (name) => {
    document.cookie = `vm_${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${accountUrl()}`;
};

export const CookieService = {
    setCookie,
    getCookie,
    deleteCookie,
};