export const actions = {
    axn_setUserInfo({commit}, payload) {
        commit('setUserInfo', payload);
    },
    axn_setEmptyUserInfo({commit}) {
        commit('setEmptyUserInfo');
    },
    axn_setShowProfile({commit}, payload) {
        commit('setShowProfile', payload);
    },
};