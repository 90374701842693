import { cloneDeep } from 'lodash';

import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

export const defaultState = {
    userInfo: {
        userId: null,
        nickName: null,
        email: null,
        name:null,
        loginProviders: [],
        consents: [],
    },
    showProfile: true,
};

export const user_info_store = {
    namespaced: true,
    state: cloneDeep(defaultState),
    actions,
    mutations,
    getters,
};