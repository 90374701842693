import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n, loadLanguageAsync } from '@/i18n/i18n-setup.js';
import * as Sentry from '@sentry/vue';
import { mParticleMetric } from '@/metrics';
import { LocaleService } from './services/locale';
import { v4 as uuid4 } from 'uuid';

Vue.config.productionTip = false;

window.getFeatureFlags = () => {
    console.log(process.env);
};

console.log(process.env.VUE_APP_COMMIT_SHORT_SHA);

mParticleMetric.init();

loadLanguageAsync(LocaleService.getLocale());

const anonymousId = uuid4();

Sentry.init({
    Vue,
    environment: process.env.VUE_APP_SENTRY_ENV,
    dsn: `${process.env.VUE_APP_SENTRY_DSN}`,
    maxBreadcrumbs: 150,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    initialScope: {
        user: {
            id: anonymousId,
        },
    },
});

try {
    Sentry.addBreadcrumb({
        message: `INIT ACCOUNTS ON: ${window.location}`,
        level: 'info',
    });
} catch (e) {
    Sentry.captureMessage('Can\'t add Init url breadcrumb');
    Sentry.captureException(e);
}


new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');