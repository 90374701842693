import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/i18n/en.json';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'en',
    silentTranslationWarn: true,
    messages,
});

const allowedLocales = ['de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'pt', 'ru', 'zh'];
const loadedLanguages = ['en'];
i18n.setLocaleMessage(i18n.locale, messages);

const setI18nLanguage = (lang) => {
    i18n.locale = lang;
    document.querySelector('html').setAttribute('lang', lang);
    return lang;
};

export const loadLanguageAsync = async (lang) => {
    if (!allowedLocales.includes(lang)) {
        lang = 'en';
    }

    if (i18n.locale === lang || loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    const messages = await import( /* webpackChunkName: "lang-[request]" */ `@/i18n/${lang}.json`);
    i18n.setLocaleMessage(lang, messages.default);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
};
