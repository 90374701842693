import { SOCIAL_LOGIN_NAME, SOCIAL_LOGIN_STATUS } from './constants';

const serializer = ({
    userId,
    nickName,
    email,
    name,
    loginProviders,
    createdAt,
    consents,
    subscription,
}) => {
    return {
        userId,
        nickName,
        email,
        name,
        loginProviders: fillLoginProviders(loginProviders),
        createdAt,
        consents,
        ...(subscription && { subscription }),
    };
};

const fillLoginProviders = (loginProviders) => Object.values(SOCIAL_LOGIN_NAME).map((providerName) => {
    const activeProvider = loginProviders.find((provider) => provider.name === providerName);

    if (activeProvider) {
        return activeProvider;
    }

    return {
        name: providerName,
        status: SOCIAL_LOGIN_STATUS.INACTIVE,
    };
});

export { serializer };