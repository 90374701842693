import Vue from 'vue';
import Vuex from 'vuex';

import { websocket_store } from './modules/websocket';
import { user_info_store } from './modules/user';

Vue.use(Vuex);

export const storeObject = {
    modules: {
        websocket_store,
        user_info_store,
    },
};

export default new Vuex.Store(storeObject);


