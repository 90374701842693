<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
    name: 'App',
    mounted() {
        sessionStorage.removeItem('cf-token');
        window._turnstileCb = () => {
            // eslint-disable-next-line no-undef
            turnstile.render('#cf-widget', {
                sitekey: process.env.VUE_APP_CLOUDFLARE_SITE_KEY,
                theme: 'light',
                callback: (token) => {
                    sessionStorage.setItem('cf-token', token);
                },
            });
        };
    },
};
</script>

<style lang="scss">
* {
	font-weight: unset;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: $f-default;
}

html {
	overflow: auto !important;
}

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: $c-black;
	color: $c-white;

	@media (max-width: 767px) {
		height: auto;
		align-items: initial;
	}
}

a {
	color: $c-primary;
	text-decoration: none;
	&:visited {
		color: $c-primary;
	}
	&:hover {
		color: white;
	}
}

h2 {
	color: white;
	font-size: 24px;
	margin-bottom: 20px;
	text-align: center;
}

 .xpaystation-widget-lightbox-overlay {
    opacity: 0!important;
  }

  .xpaystation-widget-lightbox-content {
    height: 95%!important;
    top: 0px!important;
  }
</style>
