export const mutations = {
    set_websocketError(state, {value}) {
        state.websocket.hasError = value;
    },

    set_websocketUser(state, value) {
        state.websocket.user = value;
    },

    set_websocketActionType(state, value) {
        state.websocket.actionType = value;
    },

    set_websocketActionID(state, value) {
        state.websocket.actionID = value;
    },

    set_websocketFail(state, {value}) {
        state.websocket.fail = value;
    },

    set_websocketPorts(state, value) {
        state.websocket.ports = [value];
    },
};