
import { SOCIAL_LOGIN_NAME, SOCIAL_LOGIN_STATUS } from './constants';

const CONSENT_STATUS = {
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
};

const CONSENT_NAME = {
    marketing: 'marketing',
    dataCollection: 'data_collection',
    aiBetaProgram: 'ai_beta_program',
    betaProgram: 'beta_program',
};

const isSocialActive = (social) => {
    return social.status === SOCIAL_LOGIN_STATUS.ACTIVE;
};
const isApple = (social) => {
    return social.name === SOCIAL_LOGIN_NAME.APPLE;
};
const isTwitch = (social) => {
    return social.name === SOCIAL_LOGIN_NAME.TWITCH;
};
const isDiscord = (social) => {
    return social.name === SOCIAL_LOGIN_NAME.DISCORD;
};
const isGoogle = (social) => {
    return social.name === SOCIAL_LOGIN_NAME.GOOGLE;
};
const generateConsent = (consents) => {
    const consentsKeys = Object.keys(consents);
    const userConsents = consentsKeys
        .map((consent) => ({
            type: CONSENT_NAME[consent],
            status: getConsentStatus(consents[consent]),
        }));

    return userConsents;
};

const getConsentStatus = (status) => status ? CONSENT_STATUS.ACCEPTED : CONSENT_STATUS.DECLINED;


const userDomainModule = {
    CONSENT_STATUS,
    CONSENT_NAME,
    isSocialActive,
    isApple,
    isTwitch,
    isDiscord,
    isGoogle,
    generateConsent,
};

export { userDomainModule };
