import mParticle from '@mparticle/web-sdk';
import { LocaleService } from '@/services/locale';
import { CookieService } from '@/services/cookie';
import { CommonProperties, services } from '@voicemod1/event-tracking-helper';

const EVENT_TYPE = mParticle.EventType;

const EVENTS = {
    DOWNLOAD_VIEW: 'Download App View',
    DOWNLOAD_TRIGGERED: 'Download Triggered',
    LOGIN_CODE_VALIDATE: 'Login Code Validate',
    LOG_IN_SUCCESS_VIEW: 'Log in Success View',
    DEEP_LINK_CLICKED: 'Deep Link Clicked',
    ORDER_COMPLETED: 'Order Completed',
    CHECKOUT_COMPLETED: 'Checkout Complete',
    CHECKOUT_STARTED: 'Checkout Started',
    CHECKOUT_LANDING: 'Checkout Landing',
    CHECKOUT_LANDING_NOT_AUTHENTICATED: 'Checkout Landing Not Authenticated',
    SIGN_IN_VIEW: 'Sign In View',
    SIGN_IN_SELECT_CLICK: 'Sign In Select Click',
    SIGN_IN_CONSENT_VIEW: 'Sign In User Consent View',
    CONSENT_BUTTON_CLICK: 'User Consent Button Click',
    SIGN_IN_ERROR: 'Sign in error',
    WEBSOCKET_CONNECTED: 'Websocket connected',
    WEBSOCKET_DISCONNECTED: 'Websocket disconnected',
    ACTIVATION_EXPIRED_VIEW: 'Activation Expired View',
    DOWNLOAD_VM_CONTROLLER_VIEW: 'Download VMController Web Viewed',
    DOWNLOAD_VM_SOUNDBOARD_VIEW: 'VMSoundboardApp Download Viewed',
    STORE_BUTTON_CLICKED: 'Store Button Clicked',
    STORE_BUTTON_CLICKED_SOUNDBOARDAPP: 'VMSoundboardApp Store Button Clicked',
    USER_ACCOUNT_VIEW: 'User Account View',
    CANCEL_SUBSCRIPTION_CLICKED: 'Cancel Subscription Clicked',
    SUBSCRIPTION_CANCELED: 'Subscription Cancelled',
    ACCOUNT_DELETED: 'Account Deleted',
    UNLOCK_PROMOTION_SHOWED: 'Unlock Promotion Showed',
    UNLOCK_PROMOTION_CLICKED: 'Unlock Promotion Clicked',
    UNLOCK_PROMOTION_VIEW: 'Unlock Promotion View',
    GIFT_LICENSE: 'Gift a License',
    BEG_VOICEMOD: 'Beg a License',
    ACCOUNTS_WEB_CHECKCOUT_ERROR: 'Checkout Error',
    ACCOUNTS_WEB_UNEXPECTED_ERROR_OCCURRED: 'Accounts Web Unexpected Error Occurred',
};

const init = () => {
    const {VUE_APP_ENVIRONMENT, VUE_APP_MPARTICLE_KEY} = process.env;
    const isDevelopmentMode = VUE_APP_ENVIRONMENT === 'development';
    const cookieDomain = isDevelopmentMode ? 'voicemod.dev' : 'voicemod.net';

    mParticle.init(VUE_APP_MPARTICLE_KEY, {
        isDevelopmentMode,
        v1SecureServiceUrl: 'mp.voicemod.net/webevents/v1/JS/',
        v2SecureServiceUrl: 'mp.voicemod.net/webevents/v2/JS/',
        v3SecureServiceUrl: 'mp.voicemod.net/webevents/v3/JS/',
        configUrl: 'mp.voicemod.net/tags/JS/v2/',
        identityUrl: 'mp.voicemod.net/identity/v1/',
        aliasUrl: 'mp.voicemod.net/webevents/v1/identity/',
        useCookieStorage: true,
        cookieDomain,
    });
};

const login = (data) => {
    const identityRequest = {
        userIdentities: data,
    };

    const identityCallback = (result) => {
        result.getUser().setUserAttribute('locale', LocaleService.getLocale());
    };

    mParticle.Identity.login(identityRequest, identityCallback);

};

const track = async (event, params = {}, eventType = EVENT_TYPE.Navigation) => {
    const commonProperties = new CommonProperties({serviceName: services.ACCOUNTVOICEMODNET });
    await commonProperties.enableCountryCode();

    const downloadId = CookieService.getCookie('download_id');

    mParticle.logEvent(
        event,
        eventType,
        {
            ...params,
            ...commonProperties.toJSON(),
            download_id: downloadId,
        },
    );
};

const setUserAttribute = (attribute, value) => {
    const user = mParticle.Identity.getCurrentUser();

    if (!user) {
        return;
    }

    if (Array.isArray(value)) {
        return user.setUserAttributeList(attribute, value);
    }
    return user.setUserAttribute(attribute, value);
};

const mParticleMetric = {
    EVENTS,
    init,
    login,
    track,
    EVENT_TYPE,
    setUserAttribute,
};

export { mParticleMetric };
